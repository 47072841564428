import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import NoticeItem from '@/components/Article/NoticeItem';
import BoardSearchBar from '@/components/BoardSearchBar';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import BasicPagination from '@/components/Pagination/BasicPagination';
import Section from '@/components/Section';
import { BOARD_KIND_NOTICE } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { getCurrentPageInfo, PageInfo } from '@/helpers/PaginationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';

const NoticeList: FC<PageProps> = ({ location }) => {
  const qs = queryString.parse(location.search);
  // 검색중일때 현재 페이지 데이터
  const searchCurrentPage = qs.currentPage ? Number(qs.currentPage) : 1;
  const keyword = qs.keyword as string;
  const [nodes, setNodes] = useState<BoardVo[]>([]);
  const [topNodes, setTopNodes] = useState<BoardVo[]>([]);
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>();

  // 공지사항 중요 목록 로드
  const loadTopNoticeList = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: [BOARD_KIND_NOTICE],
        topYn: 'Y',
      });
      setTopNodes(data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 공지사항 목록 로드
  const loadNoticeList = useCallback(async () => {
    try {
      const {
        totalCount,
        data,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_NOTICE],
        page: Number(location.pathname.split('/').at(-1)),
        pagePerCount: 10,
        topYn: 'N',
      });

      // 페이지네이션 정보
      const noticeTotalCount = totalCount as any;
      const pageInfo = getCurrentPageInfo({
        totalItemCount: noticeTotalCount,
        baseUri: '/about-us/notice',
        pageGroupSize: 5,
        currentPage: Number(location.pathname.split('/').at(-1)),
        itemsPerPage: 10,
      });
      setCurrentPageInfo(pageInfo);

      // 공지사항 목록
      const noticeList = data as any;
      setNodes(noticeList);
    } catch (e) {
      console.error(e);
    }
  }, [location.pathname]);

  // 동적 데이터 로드
  const loadPage = useCallback(
    async (targetPage: number, targetKeyword?: string) => {
      const {
        resultCode,
        data: _data,
        totalCount,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_NOTICE],
        keyword: targetKeyword,
        page: targetPage,
        pagePerCount: currentPageInfo?.itemsPerPage,
      });

      const newData = _data as any;

      if (resultCode === `success`) {
        const boardPageInfo = getCurrentPageInfo({
          totalItemCount: totalCount || 0,
          currentPage: targetPage,
          pageGroupSize: currentPageInfo?.pageGroupSize,
          itemsPerPage: currentPageInfo?.itemsPerPage,
          baseUri: currentPageInfo?.baseUri,
          keyword: targetKeyword,
        });

        setNodes(newData);
        setCurrentPageInfo(boardPageInfo);
      }
    },
    [
      currentPageInfo?.baseUri,
      currentPageInfo?.itemsPerPage,
      currentPageInfo?.pageGroupSize,
    ],
  );

  // keyword 변경되면 업데이트
  useEffect(() => {
    if (keyword) {
      loadPage(searchCurrentPage, keyword as string);
    } else {
      loadTopNoticeList();
      loadNoticeList();
    }
  }, [searchCurrentPage, keyword, loadPage, loadNoticeList, loadTopNoticeList]);

  return (
    <LayoutWithTitle
      location={location}
      title="공지사항"
      description="for every child, notice"
    >
      <Section id="noticeSection">
        <Container
          css={`
            padding-top: 96px;
            ${breakpoint(640)} {
              padding-top: 48px;
            }
          `}
        >
          <Row searchBar justifyContent="flex-end">
            <BoardSearchBar
              initialKeyword={keyword}
              onSearch={(value) => {
                navigate(
                  `?${queryString.stringify({
                    keyword: value,
                  })}#noticeSection`,
                );
              }}
            />
          </Row>
          <Row boardlist>
            <ul
              css={`
                width: 100%;
              `}
            >
              {!keyword &&
                topNodes.map((notice) => (
                  <NoticeItem
                    basePath={currentPageInfo?.baseUri}
                    queryString={location.search}
                    notice={notice}
                    key={notice.boardIndexNumber}
                  />
                ))}
              {nodes.map((notice) => (
                <NoticeItem
                  basePath={currentPageInfo?.baseUri}
                  queryString={location.search}
                  notice={notice}
                  key={notice.boardIndexNumber}
                />
              ))}
            </ul>
          </Row>
          <Row
            justifyContent="center"
            css={`
              margin: 64px 0;
            `}
          >
            <BasicPagination {...currentPageInfo} anchor="#noticeSection" />
          </Row>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default NoticeList;
